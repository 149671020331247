import jwt_decode from 'jwt-decode';
import session from 'Common/scripts/session';
import domain from 'Common/scripts/domain';
import isRefreshTokenValid from './scripts/isRefreshTokenValid';

// * https://redux.js.org/tutorials/fundamentals/part-4-store#writing-custom-middleware

// * refreshTokenMiddleware
// before each api call, the refresh token:
// 1. checks for validity
// 2. updates if possible
// 3. sends users to the sign in page if the token can't be updated

const getRefreshOpts = () => {
    const dynamicRegistrationDomain = domain.getDynamicRegistrationApi(window.env.NODE_ENV);
    const tokens = session.getUserData();

    const opts = {
        path: `${dynamicRegistrationDomain}/users/refresh`, // this call needs to go to a different domain than the others
        init: {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${tokens?.accessToken}`,
                ...(window.env.API_SUB_KEY && {
                    'Ocp-Apim-Subscription-Key': window.env.API_SUB_KEY
                })
            },
            body: JSON.stringify({ RefreshToken: tokens?.refreshToken })
        }
    };

    return opts;
};

const refreshTokenMiddleware = storeApi => next => action => {
    const refreshTokenIsValid = isRefreshTokenValid();

    // if the refresh token is still valid, continue
    if (refreshTokenIsValid) {
        return next(action);
    }

    // try updating the refresh token
    const updateRefreshToken = async () => {
        try {
            const { path, init } = getRefreshOpts();
            const response = await fetch(path, init);
            const responseData = await response.json();

            // save the new token to the cookie
            session.storeUserData(
                {
                    accessToken: responseData.AccessToken,
                    refreshToken: responseData.RefreshToken,
                    idToken: jwt_decode(responseData.IdToken)
                },
                true
            );
            return next(action);
        } catch {
            // when refresh fails, take the user back to sign in
            window.location = window.env.REG_DOMAIN + '/signin';
        }
    };

    updateRefreshToken();
};

export default refreshTokenMiddleware;
