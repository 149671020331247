import jwt_decode from 'jwt-decode';
import session from 'Common/scripts/session';

const checkIfRestricted = async (refresh, isLoading, currentPath, referrer = null) => {
    const sess = session.getUserData();
    // if we have data in the session
    if (Object.values(sess).length > 0) {
        const idToken = sess.idToken;
        if (idToken) {
            const registrationStatus = idToken.extension_userRegistrationStatus;
            const expirationTime = idToken.exp * 1000;
            const timeNow = new Date().getTime();

            // check if the token is expired
            if (timeNow >= expirationTime) {
                // return the results so the info below makes it back up to the Route component
                try {
                    const response = await refresh().unwrap();
                    if (response.redirectUrl) {
                        window.location = response.redirectUrl;
                    }
                    // success case things
                    const state = {
                        idToken: jwt_decode(response.IdToken),
                        refreshToken: response.RefreshToken,
                        accessToken: response.AccessToken
                    };
                    session.storeUserData(state, true);
                    if (state.idToken.extension_userRegistrationStatus >= 5) {
                        return {};
                    }
                } catch (err) {
                    // todo: ?
                }
            } else {
                return {};
            }
        }
    }
    window.location = window.env.REG_DOMAIN + '/signin/';
};
export default checkIfRestricted;
