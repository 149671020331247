import { createTheme } from '@mui/material';
// import { blue } from '@mui/material/colors';

const theme = createTheme({
    palette: {
        common: {
            black: '#595555',
            white: '#efefef'
        },
        primary: {
            main: '#635BFF',
            dark: '#333060', //* medium blue
            contrastText: '#fff'
        },
        secondary: {
            // main: '#848280',
            main: '#232149',
            light: '#666666',
            contrastText: '#fff'
        },

        error: {
            main: '#db2126',
            contrastText: '#fff'
        },
        background: {
            paper: 'white'
        }
    },
    typography: {
        modalHeading: {
            fontSize: 16,
            fontWeight: 400,
            color: 'rgb(0, 0, 0, 0.6)' // secondary.light
        }
    },
    sizing: {
        logo: {
            height: 50
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.secondary.main
                })
            }
        },
        MuiButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    padding: '.75em',
                    textTransform: 'none'
                    // '&:hover': {
                    //     boxShadow: theme.shadows[3]
                    // }
                })
            }
        },
        MuiCard: {
            styleOverrides: {
                root: ({ theme }) => ({
                    flexGrow: 1,
                    margin: 'auto',
                    backgroundColor: 'white',
                    width: '100%',
                    boxShadow: theme.shadows[3],
                    padding: theme.spacing(2),
                    borderRadius: '5px'
                })
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white'
                }
            }
        },
        MuiModal: {
            variants: [
                {
                    props: { variant: 'permissions' },
                    style: {
                        '> .MuiPaper-root': {
                            height: '610px'
                        }
                    }
                }
            ]
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    backgroundColor: 'white'
                }
            }
            // todo: not working?
            // variants: [
            //     {
            //         props: { variant: 'onpaper' },
            //         style: {
            //             outline: '1px solid red'
            //         }
            //     }
            // ]
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    modalHeading: 'h2'
                }
            }
        }
    }
});

export { theme };
