import React, { useState, createContext } from 'react';

// * Make a mini store
// to retain the showLicenseSummary value through re-renders.
// Otherwise we'll see a loader with every route change
// for users who are NOT allowed to view decommissions -M

const CTX = createContext();

const NavStore = props => {
    const [showLicenseSummary, setShowLicenseSummary] = useState(null);
    return (
        <CTX.Provider value={[showLicenseSummary, setShowLicenseSummary]}>
            {props.children}
        </CTX.Provider>
    );
};

export { NavStore as default, CTX };
