import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import App from 'App';
import rtkStore from './RTKStore';
import NavStore from 'layouts/Dashboard/Navigation/NavStore';
import * as serviceWorker from 'serviceWorker';
// MUI Componentry
import { ThemeProvider } from '@mui/material/styles';
// ! disabling this until we're ready to switch to the new style set
// import { theme } from 'Common/styles/theme';
import { theme } from 'styles/theme';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={rtkStore}>
        {/* NavStore needs to wrap a componenent that won't reload on every page like Dashboard does */}
        <NavStore>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </NavStore>
    </Provider>
);

// This service worker is disabled currently. While service workers can help
// the app run quicker - and work offline - it causes the index.html to be
// cached, unless the app is reloaded.
//
// This can be changed from `unregister` to `register` to re-enable service workers.
serviceWorker.unregister();
