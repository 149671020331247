import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useAppSettingsQuery } from 'features/apiSlice';
import Loader from 'Common/components/Loader';
import RequireAuth from 'routes/RequireAuth';

const NotFound = lazy(() => import('pages/NotFound'));
const Error = lazy(() => import('pages/Error'));
const Account = lazy(() => import('pages/Account'));
const LicenseUsage = lazy(() => import('pages/LicenseUsage'));
const UserDecommissions = lazy(() => import('pages/UserDecommissions'));
// const Company = lazy(() => import('pages/Company'));
const AccountSettings = lazy(() => import('pages/AccountSettings'));
const CurrentUsers = lazy(() => import('pages/CurrentUsers'));
const BrowsePPKs = lazy(() => import('components/BrowsePPKs'));

const guidPattern =
    '(/account/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/)';

function AdminAppRoutes() {
    // the Account page handles errors & loading states from this request
    // not ideal to make a fetch request here but we need its data to load the correct routes -M
    const { data: appSettings, isLoading, isError, error } = useAppSettingsQuery();

    return (
        <Router>
            <Suspense fallback={<Loader loading={true} isPageLoader />}>
                <Routes>
                    {/* Home page is /account */}
                    <Route path="/" element={<Navigate to="/account" />} />
                    {/* For requests to /account/<GUID>, redirect to /account/ */}
                    <Route
                        path="/account/:guid"
                        element={<Navigate to="/account" replace={true} />}
                    />
                    {/* Account will handle any fetch request failures for appSettings */}
                    <Route
                        path="/account"
                        element={
                            <RequireAuth>
                                <Account
                                    appSettings={appSettings}
                                    isLoading={isLoading}
                                    isError={isError}
                                    error={error}
                                />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/licenseusage/licensing"
                        element={<Navigate to="/licenseusage" replace={true} />}
                    />
                    <Route
                        path="/licenseusage"
                        element={
                            <RequireAuth>
                                <LicenseUsage />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/userdecommissions"
                        element={
                            <RequireAuth>
                                <UserDecommissions />
                            </RequireAuth>
                        }
                    />
                    {/* <Route path="/company/" element={<Company />} /> */}
                    <Route
                        path="/currentusers"
                        element={
                            <RequireAuth>
                                <CurrentUsers />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/accountsettings"
                        element={
                            <RequireAuth>
                                <AccountSettings />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/ppk"
                        element={
                            <RequireAuth>
                                <BrowsePPKs />
                            </RequireAuth>
                        }
                    />
                    <Route path="/error" element={<Error />} />
                    <Route element={<NotFound />} />
                </Routes>
            </Suspense>
        </Router>
    );
}

export default AdminAppRoutes;
