import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from 'features/apiSlice';
import refreshTokenMiddleware from 'features/refreshTokenMiddleware';
import errorCatchingMiddleware from 'features/errorCatchingMiddleware';

export default configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer
    },

    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(
            errorCatchingMiddleware,
            refreshTokenMiddleware,
            apiSlice.middleware
        )
});
