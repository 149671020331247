const deployStatusToString = status => {
    switch (status) {
        case 0:
            return 'Active';
        case 1:
            return 'Pending';
        case 2:
            return 'Decommissioned';
        case 3:
            return 'Recommissioned';
        case 4:
            return 'Deleted';
        default:
            return null;
    }
};

const getNewDate = dateString => {
    const date = new Date(dateString).toLocaleDateString('en-US');
    const time = new Date(dateString).toLocaleTimeString('en-US');
    return `${date} ${time}`;
};

const parseDeployments = (deployments, showActiveMachinesOnly) => {
    let deploymentsArr = deployments;

    // only include active machines
    if (showActiveMachinesOnly) {
        deploymentsArr = deployments.filter(machine => machine.MachineDeployedStatus === 0);
    }

    return deploymentsArr.map(deployment => {
        const { MachinesDeployedId, MachineDeployedStatus, DeployedDatePrecise, ...rest } =
            deployment;

        return {
            ...rest,
            MachineDeployedStatus: deployStatusToString(MachineDeployedStatus),
            // add an id so that MUI's DataGridPro can display things properly
            id: MachinesDeployedId,
            // add another date so we can have specific formatting in the csv export
            DeployedDateCsv: getNewDate(DeployedDatePrecise),
            MachinesDeployedId
        };
    });
};

export default parseDeployments;
