import React from 'react';
// import smartFocusRing from 'smart-focus-ring';
import Routes from 'Routes';
import 'scripts/fontAwesome';
import 'styles/global-styles.scss';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(
    'afd814ca68a0b0bf681e47e3fdcd6104T1JERVI6Mzk4MzEsRVhQSVJZPTE2NzkwNjYxMDAwMDAsS0VZVkVSU0lPTj0x'
);

// smartFocusRing();

export default function App() {
    return <Routes />;
}
