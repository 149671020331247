import { isRejectedWithValue } from '@reduxjs/toolkit';
import React from 'react';
import ConnectionError from 'Common/components/ConnectionError';

const errorCatchingMiddleware = api => next => action => {
    if (isRejectedWithValue(action)) {
        const { payload, error } = action;

        // set error messages based on connection to the server
        if (payload.status === 'FETCH_ERROR') {
            // payload.data is reserved for server response data
            // so we put the error message in a new userDisplayMessage value
            //
            // set a generic error since the server was unable to provide one
            payload.userDisplayMessage = <ConnectionError />;
            console.error(payload.error);
        } else {
            payload.userDisplayMessage = payload.data.Description;
            console.error(
                payload.data.ErrorCode +
                    ' (' +
                    error.message +
                    ') ' +
                    payload.data.Message +
                    ' \u2013 ' +
                    payload.data.Description
            );
        }
    }
    return next(action);
};

export default errorCatchingMiddleware;
